var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%"}},[_c('div',[_c('div',{staticClass:"Enter-top"},[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("物联电表")]),_c('a-breadcrumb-item',[_vm._v("阀控记录")])],1)],1),_c('a-tabs',{staticStyle:{"margin-left":"10px"},attrs:{"default-active-key":"1"},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"报警方案"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"电费"}}),_c('a-tab-pane',{key:"3",attrs:{"tab":"水费"}}),_c('a-tab-pane',{key:"4",attrs:{"tab":"物业费"}})],1)],1)]),_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',{staticClass:"search-wrap flex-d-row"},[_c('div',{staticStyle:{"width":"170px","border-right":"1px solid #d9d9d9"}},[_c('div',{staticClass:"regionleft"},[_vm._v("区域信息")]),_c('a-tree',{attrs:{"replace-fields":_vm.replaceFields,"tree-data":_vm.treeData,"value":_vm.title,"defaultExpandAll":true},on:{"select":_vm.onSelect}})],1),_c('div',{staticStyle:{"flex":"1","padding":"24px"}},[_c('div',{staticClass:"in-top"},[_c('a-form',{staticClass:"ant-advanced-search-form",staticStyle:{"padding":"0 24px"},attrs:{"form":_vm.fom},on:{"submit":_vm.handleSearch}},[_c('a-row',{staticClass:"flex-d-row",staticStyle:{"flex-wrap":"wrap"},attrs:{"gutter":24}},[_vm._l((_vm.textlist),function(i,index){return _c('a-col',{key:index},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":i}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `${i}`,
                        {
                          rules: [],
                        },
                      ]),expression:"[\n                        `${i}`,\n                        {\n                          rules: [],\n                        },\n                      ]"}],staticStyle:{"width":"178px"},attrs:{"placeholder":'请输入' + i}})],1)],1)}),_c('a-col',{staticClass:"a-col-top"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 搜索 ")]),_c('a-button',{style:({ marginLeft: '8px' }),on:{"click":_vm.handleReset}},[_vm._v(" 清空 ")])],1)],2)],1),_c('a-button',[_vm._v("添加")])],1),_c('a-table',{attrs:{"row-selection":_vm.rowSelection,"rowKey":"id","pagination":false,"columns":_vm.columns,"data-source":_vm.modulelist,"rowClassName":_vm.rowClassName},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v("编辑")])]}}])}),_c('a-pagination',{attrs:{"page-size":_vm.params.pageSize,"show-quick-jumper":"","default-current":2,"total":_vm.total},on:{"change":_vm.onpagesize},model:{value:(_vm.params.current),callback:function ($$v) {_vm.$set(_vm.params, "current", $$v)},expression:"params.current"}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }